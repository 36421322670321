"use strict";

const { loadIfBowserSupported } = require("../lib/bowser-unsupported.js");
const Configuration = require("../configuration.js");

window.CONFIGURATION = Configuration;

import * as Sentry from "@sentry/browser";

import { Elm } from "./Main.elm";
import images from "./assets/images/*.*";

loadIfBowserSupported(function () {
  const sentryDsn = `https://${CONFIGURATION.sentryKey}@${CONFIGURATION.sentryOrganizationId}.ingest.sentry.io/${CONFIGURATION.sentryProjectId}`;
  Sentry.init({
    dsn: sentryDsn,
    environment: CONFIGURATION.sentryEnvironment,
    release: CONFIGURATION.sentryReleaseVersion,
    maxValueLength: 1000,
  });
  Sentry.setExtra("api_version", CONFIGURATION.apiVersion);

  const AmplitudePort = require("./Ports/amplitude.js");
  const CannyPort = require("./Ports/canny.js");
  const CKEditorPort = require("./Ports/ck-editor.js");
  const DashboardChartPort = require("./Ports/dashboard-chart.js");
  const ClipboardPort = require("./Ports/clipboard.js");
  const GlobalSearchPort = require("./Ports/global-search.js");
  const GooglePlaces = require("./Ports/google-places.js");
  const GoogleRecaptchaPort = require("./Ports/google-recaptcha.js");
  const HinterViewPort = require("./Ports/hinter-view.js");
  const IntercomPort = require("./Ports/intercom.js");
  const LocalStoragePort = require("./Ports/local-storage.js");
  const NavbarPort = require("./Ports/navbar.js");
  const NavigationPort = require("./Ports/navigation.js");
  const NewTab = require("./Ports/new-tab.js");
  const ToastPort = require("./Ports/toast.js");
  const PasswordStrength = require("./Ports/password-strength.js");
  const QRDecoder = require("./Ports/qr-decoder.js");
  const SentryPort = require("./Ports/sentry.js");
  const SessionPort = require("./Ports/session.js");
  const StripeTerminalPort = require("./Ports/stripe-terminal.js");
  const StripePort = require("./Ports/stripe.js");
  const NavTabsPort = require("./Ports/nav-tabs.js");
  const ThoughtSpotPort = require("./Ports/thoughtspot.js");
  const FullStoryPort = require("./Ports/fullstory");
  const FaviconPort = require("./Ports/favicon.js");
  const PageTitlePort = require("./Ports/page-title.js");
  const DropdownPort = require("./Ports/dropdown.js");
  const RainforestPort = require("./Ports/rainforest.js");

  if (CONFIGURATION.statuspageEmbedCode) {
    setupStatusPage(CONFIGURATION.statuspageEmbedCode);
  }

  if (
    CONFIGURATION.newRelicAgentId &&
    CONFIGURATION.newRelicLicenseKey &&
    CONFIGURATION.newRelicApplicationId &&
    typeof setupNewRelic !== "undefined"
  ) {
    setupNewRelic(
      CONFIGURATION.newRelicAgentId,
      CONFIGURATION.newRelicLicenseKey,
      CONFIGURATION.newRelicApplicationId,
    );
  }

  const currentRataDieDate = () => {
    const difference = new Date().getTime() - new Date("0001-01-01").getTime();
    return Math.ceil(difference / (1000 * 3600 * 24));
  };

  // Initial data passed to Elm (should match `Flags` defined in `Shared.elm`)
  // https://guide.elm-lang.org/interop/flags.html
  var flags = {
    locationHref: location.href,
    sessionToken: SessionPort.read(),
    showRedesignToggle:
      LocalStoragePort.getItem("hint.redesign-toggle") || "always",
    currentRataDieDate: currentRataDieDate(),
    currentTime: new Date().getTime(),
    hinterViewOff: HinterViewPort.read(),
    images: Object.keys(images).map((key) => [
      key,
      Object.values(images[key])[0],
    ]),
    origin:
      window.location.origin ||
      window.location.protocol + "//" + window.location.hostname,
    debugOrTest: CONFIGURATION.debugOrTest,
    apiUrl: CONFIGURATION.apiUrl,
    apiVersion: CONFIGURATION.apiVersion || null,
    embedded: window.parent != window,
    emrHost: CONFIGURATION.emrHost,
    release: CONFIGURATION.release || null,
    nodeEnv: CONFIGURATION.nodeEnv || null,
    intercomAppId: CONFIGURATION.intercomAppId || null,
    defaultHubspotDeal: CONFIGURATION.defaultHubspotDeal || null,
    backupAuthEnabled: Configuration.backupAuthEnabled,
    providerAuthUrl: CONFIGURATION.providerAuthUrl,
  };

  // Start our Elm application
  var app = Elm.Main.init({
    node: document.getElementById("elm-main"),
    flags: flags,
  });

  // Ports go here
  // https://guide.elm-lang.org/interop/ports.html
  AmplitudePort.add(app, CONFIGURATION.amplitudeApiKey);
  CannyPort.add(app, CONFIGURATION.cannyAppId);
  CKEditorPort.add(app);
  ClipboardPort.add(app);
  DashboardChartPort.add(app);
  GlobalSearchPort.add(app);
  GooglePlaces.add(app, CONFIGURATION.googleMapsKey);
  GoogleRecaptchaPort.add(app, CONFIGURATION.googleRecaptchaSiteKey);
  HinterViewPort.add(app);
  IntercomPort.add(app, CONFIGURATION.intercomAppId);
  LocalStoragePort.add(app);
  NavbarPort.add(app);
  NavigationPort.add(app);
  NewTab.add(app);
  ToastPort.add(app, CONFIGURATION.emrHost);
  PasswordStrength.add(app);
  QRDecoder.add(app);
  SentryPort.add(app);
  SessionPort.add(app, CONFIGURATION.authClientId);
  StripeTerminalPort.add(app);
  StripePort.add(app, CONFIGURATION.hintStripePublicKey);
  NavTabsPort.add(app);
  ThoughtSpotPort.add(app);
  FullStoryPort.add(app, CONFIGURATION.fullStoryOrgId);
  FaviconPort.add(app);
  PageTitlePort.add(app);
  DropdownPort.add(app);
  RainforestPort.add(app, CONFIGURATION.rainforestEnv);
});
