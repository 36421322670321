const debugOrTest =
  process.env.DEBUG ||
  !["staging", "production"].includes(process.env.NODE_ENV);

const Configuration = {
  amplitudeApiKey: process.env.AMPLITUDE_KEY,
  apiUrl: process.env.API_URL,
  apiVersion: process.env.API_VERSION,
  authClientId: process.env.AUTH_CLIENT_ID,
  providerAuthUrl: process.env.AUTH_URL,
  backupAuthEnabled: process.env.BACKUP_AUTH === 'true',
  cannyAppId: process.env.CANNY_APP_ID,
  debugOrTest: debugOrTest,
  defaultHubspotDeal: process.env.DEFAULT_HUBSPOT_DEAL || (debugOrTest ? "16071320188" : null),
  emrHost: process.env.EMR_HOST,
  fullStoryOrgId: process.env.FULLSTORY_ORG_ID,
  googleMapsKey: `&key=${process.env.GOOGLE_MAPS_JS_KEY}`,
  googleRecaptchaSiteKey: process.env.GOOGLE_RECAPTCHA_SITE_KEY,
  hintStripePublicKey: process.env.HINT_STRIPE_PUBLIC_KEY,
  intercomAppId: process.env.INTERCOM_APP_ID,
  newRelicAgentId: process.env.NEW_RELIC_AGENT_ID,
  newRelicApplicationId: process.env.NEW_RELIC_APPLICATION_ID,
  newRelicLicenseKey: process.env.NEW_RELIC_LICENSE_KEY,
  nodeEnv: process.env.NODE_ENV,
  redirectToUri: process.env.REDIRECT_TO_URI,
  sentryOrganizationId: process.env.SENTRY_ORGANIZATION_ID,
  sentryProjectId: process.env.SENTRY_PROJECT_ID,
  sentryKey: process.env.SENTRY_KEY,
  sentryEnvironment: process.env.SENTRY_ENVIRONMENT,
  sentryReleaseVersion: process.env.RELEASE_TAG,
  skipSSOInit: process.env.SKIP_SSO_INIT === "true",
  statuspageEmbedCode: process.env.STATUSPAGE_EMBED_CODE,
  release: process.env.RELEASE_TAG,
  rainforestEnv: process.env.RAINFOREST_ENV || "sandbox",
};

module.exports = Configuration;
